<template>
  <div class="min-h-full flex flex-col">
    <template v-if="maxBookingsReached">
        <UserReachedMaxBookingsModal/>
    </template>
    <template v-if="maxPitchesReached">
      <!-- TODO: -->
        <UserReachedMaxBookingsModal/>
    </template>
    <template v-if="maxPitchesReachedForPro">
        <ProReachedMaxPitchesModal/>
    </template>
    <div class="flex justify-between">
      <h4 class="text-3xl sm:text-5xl font-bold w-2/3 leading-tight whitespace-no-wrap mt-4">
        <span v-if="(code.code && code.isValid) || totalPrice == 0">Review</span>
        <span v-else>Review &amp; Pay</span>
      </h4>
      <!-- Step counter -->
      <StepsCounter :stepCount="steps.length" :currentStep="currentStep"/>
    </div>
    <div class="mt-6 sm:mt-10 text-gray-500 font-light text-xl">
      <span v-if="(code.code && code.isValid) || totalPrice == 0">Review the details and finalize the booking request.</span>
      <span v-else>Pick your desired payment method and finalize the booking request.</span>
    </div>
    <div class="mt-12">
      <div v-if="code.code && code.isValid" class="mb-8 bg-green-100 text-green-700 p-6 rounded-md shadow-md" role="alert">
        <span class="text-sm">Your booking code is valid.</span>
      </div>
      <div v-else-if="code.code && ! code.isValid" class="mb-8 bg-red-100 text-red-700 p-6 rounded-md shadow-md" role="alert">
        <span class="text-sm">Your booking code is not valid.</span>
      </div>
      <ValidationObserver ref="bookingForm" v-slot="{ handleSubmit }" tag="div">
        <form method="post" class="block" @submit.prevent="handleSubmit(createBooking)">
          <template v-if="toBeChargedAmount > 0">
            <template v-if="paymentMethods.length && useExistingPaymentMethod">
              <div class="text-black font-medium">Payment method</div>
              <ExistingPaymentMethodCard :paymentMethod="paymentMethodToUse" />
              <div class="mt-4">
                <button @click.prevent="useExistingPaymentMethod = null" type="button" class="text-pink-500 underline">
                  Use different payment method
                </button>
              </div>
            </template>

            <template v-else>
              <CreditCard @saveCard="setSaveCard" @cardComplete="setCardComplete"/>

              <div v-if="!useExistingPaymentMethod" class="space-y-2 mt-12">
                <UseExistingPaymentMethodButton
                  v-for="(paymentMethod, index) in paymentMethods"
                  :key="`payment-methods-${index}`"
                  :paymentMethod="paymentMethod"
                  @onClick="useExistingPaymentMethod = paymentMethod.id"
                />
              </div>
            </template>
          </template>

          <div class="mt-6" v-if="guestioCashBalance > 0 && totalPrice > 0">
            <div>
              <label class="cursor-pointer select-none flex items-center">
                <input type="checkbox" v-model="useGuestioCash" class="form-checkbox h-5 w-5 text-pink-500">
                <span class="ml-2"> Use Guestio Cash <span class="font-semibold">(${{ guestioCashAmount }})</span></span>
              </label>
            </div>
          </div>

          <template v-if="isPitch && pitchSettings.guaranteed_response">
            <AddGuaranteedResponse
              :price="pitchSettings.guaranteed_response_price"
              entity="guest"
              class="mt-6"
            />
          </template>

          <div :class="{
            'mt-10 pt-8 border-t border-gray-300': (guestioCashBalance && totalPrice > 0) || toBeChargedAmount > 0 || pitchSettings.guaranteed_response
          }">
            <div class="flex items-center justify-between">
              <template v-if="isPitch">
                <div>
                  Pitch for <em class="font-medium">{{ selectedBookingOption.name }} </em>
                  <template v-if="['miniview', 'media-feature'].includes(selectedBookingOption.slug)">
                    <em class="text-gray-500">
                      ({{ selectedBookingOption.amount || 1 }} x ${{ parseFloat(selectedBookingOption.price.price).toFixed(2) }})
                    </em>
                  </template>
                  <template v-else-if="selectedBookingOption.slug === 'virtual-interview'">
                    <em class="text-gray-500">({{ selectedBookingOption.price.interview_duration }} mins)</em>
                  </template>
                  <template v-else-if="selectedBookingOption.slug === 'live-platform'">
                    <em class="text-gray-500">(for 2 hours)</em>
                  </template>
                </div>

                <div class="text-right whitespace-nowrap">
                  ${{ parseFloat(pitchSettings.price).toFixed(2) }}
                </div>
              </template>

              <template v-else>
                <div>
                  {{ selectedBookingOption.name }}
                  <template v-if="['miniview', 'media-feature'].includes(selectedBookingOption.slug)">
                    <span class="text-gray-500">
                      ({{ selectedBookingOption.amount || 1 }} x ${{ parseFloat(selectedBookingOption.price.price).toFixed(2) }})
                    </span>
                  </template>
                  <template v-else-if="selectedBookingOption.slug === 'virtual-interview'">
                    <span class="text-gray-500">({{ selectedBookingOption.price.interview_duration }} mins)</span>
                  </template>
                  <template v-else-if="selectedBookingOption.slug === 'live-platform'">
                    <span class="text-gray-500">(for 2 hours)</span>
                  </template>
                </div>
                <div class="text-right whitespace-nowrap">
                  <template v-if="['miniview', 'media-feature'].includes(selectedBookingOption.slug)">
                    ${{ parseFloat(selectedBookingOption.price.price * (selectedBookingOption.amount || 1)).toFixed(2) }}
                  </template>
                  <template v-else>
                    ${{ parseFloat(selectedBookingOption.price.price).toFixed(2) }}
                  </template>
                </div>
              </template>
            </div>

            <div v-if="upsellsPrice" class="mt-4 flex items-center justify-between">
              <div>
                Promotional Opportunities
              </div>
              <div class="text-right whitespace-nowrap">
                ${{ parseFloat(upsellsPrice).toFixed(2) }}
              </div>
            </div>

            <div v-if="wantsGuaranteedResponse" class="mt-4 flex items-center justify-between">
              <div>Guaranteed Response</div>
              <div class="text-right whitespace-nowrap">
                ${{ parseFloat(pitchSettings.guaranteed_response_price).toFixed(2) }}
              </div>
            </div>

            <div
              v-if="isPitch && userIsPro && pitchSettings.pro_is_free"
              class="mt-4 flex items-center justify-between"
            >
              <div>PRO discount</div>
              <div class="text-right whitespace-nowrap">
                - ${{ parseFloat(pitchSettings.price).toFixed(2) }}
              </div>
            </div>

            <div v-if="useGuestioCash" class="mt-4 flex items-center justify-between">
              <div>
                Guestio Cash
              </div>
              <div class="text-right whitespace-nowrap">
                - ${{ parseFloat(guestioCashAmount).toFixed(2) }}
              </div>
            </div>

            <div v-if="code.code && code.isValid" class="mt-4 flex items-center justify-between">
              <div>
                Booking Code
              </div>
              <div class="text-right whitespace-nowrap">
                - ${{ parseFloat(totalPrice).toFixed(2) }}
              </div>
            </div>

            <div v-if="totalPrice - guestioCashAmount > 0 && totalPrice - guestioCashAmount < 0.5" class="mt-4 flex items-center justify-between">
              <div>
                Rounding for minimum charge
              </div>
              <div class="text-right whitespace-nowrap">
                ${{ parseFloat(0.5 - (totalPrice - guestioCashAmount)).toFixed(2) }}
              </div>
            </div>

            <div class="mt-8 font-bold text-lg flex items-center justify-between">
              <div>
                Total
              </div>
              <div class="text-right whitespace-nowrap">
                ${{ parseFloat(toBeChargedAmount).toFixed(2) }}
              </div>
            </div>
          </div>

          <div v-if="paymentSetupError" class="mt-6">
            <p class="text-red-500">There was an error while attempting to process the payment.</p>
            <p v-if="errorDetails" class="mt-2 text-red-500">{{ errorDetails }}</p>
          </div>

          <div class="mt-12">
            <button type="submit" class="font-semibold rounded-full h-16 sm:h-20 bg-indigo-gradiant text-white text-base sm:text-xl sm:text-2xl w-full flex justify-center items-center" :class="{'opacity-50': loading || ! stepComplete}" :disabled="loading || ! stepComplete">
              <span v-if="loading">
                <loading-icon class="h-5 w-5"/>
              </span>
              <template v-else>
                <svg class="w-6 h-6" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor"><path d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path></svg>
                <span v-if="toBeChargedAmount > 0" class="ml-2">
                  Confirm payment ${{ toBeChargedAmount }}
                </span>
                <span v-else class="ml-2">
                  Confirm {{ isPitch ? 'pitch' : 'booking' }}
                </span>
              </template>
            </button>

            <div class="mt-8 flex justify-center">
              <SecureCheckoutSvg class="h-10" />
            </div>

            <div class="text-center mt-4">
              <button @click.prevent="prevStep" type="button" class="text-sm text-pink-500 hover:underline">Previous step</button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
  import api from '@/api'
  import StepsCounter from '@/components/StepsCounter'
  import SecureCheckoutSvg from '@/components/svgs/SecureCheckout'
  import { confirmCardSetup } from 'vue-stripe-elements-plus'
  import UserReachedMaxBookingsModal from '@/components/modals/UserReachedMaxBookingsModal'
  import ProReachedMaxPitchesModal from '@/components/modals/ProReachedMaxPitchesModal'
  import AddGuaranteedResponse from '@/components/Book/AddGuaranteedResponse';
  import CreditCard from '@/components/payment-methods/CreditCard';
  import ExistingPaymentMethodCard from '@/components/payment-methods/ExistingPaymentMethodCard';
  import UseExistingPaymentMethodButton from '@/components/payment-methods/UseExistingPaymentMethodButton';

  export default {
    components: {
      StepsCounter,
      SecureCheckoutSvg,
      UserReachedMaxBookingsModal,
      ProReachedMaxPitchesModal,
      AddGuaranteedResponse,
      CreditCard,
      ExistingPaymentMethodCard,
      UseExistingPaymentMethodButton,
    },

    data() {
      return {
        loading: false,
        intent: null,
        price: null,
        cardComplete: false,
        card: null,
        paymentSetupError: false,
        errorDetails: null,
        defaultPaymentMethod: null,
        paymentMethods: [],
        useExistingPaymentMethod: null,
        saveCard: false,
        guestioCashBalance: 0,
        useGuestioCash: false,
        maxBookingsReached: false,
        maxPitchesReached: false,
        maxPitchesReachedForPro: false,
      }
    },

    watch: {
      useGuestioCash(useGuestioCash) {
        if (useGuestioCash) {
          this.$store.commit('guestBooking/setGuestioCash', this.guestioCashAmount)
          return
        }

        this.$store.commit('guestBooking/setGuestioCash', 0)
      },

      totalPrice() {
        if (this.useGuestioCash) {
          this.$store.commit('guestBooking/setGuestioCash', this.guestioCashAmount);
        }
      },

      useExistingPaymentMethod(paymentMethodId) {
        this.generatePaymentIntent()
        if (paymentMethodId) {
          this.$store.commit('guestBooking/setPaymentMethod', paymentMethodId)
        }
      }
    },

    computed: {
      guest() {
        return this.$store.getters['guest/getGuest'];
      },

      totalPrice() {
        return parseFloat(this.price) + parseFloat(this.upsellsPrice) + parseFloat(this.guaranteedResponsePrice);
      },

      selectedBookingOption() {
        return this.$store.getters['guestBooking/selectedBookingOption']
      },

      miniviewQuestions() {
        return this.$store.getters['guestBooking/miniviewQuestions']
      },

      user() {
        return this.$store.getters['auth/getUser']
      },

      userIsPro() {
        return this.user.account_type == 'pro'
      },

      stepComplete() {
        if (this.toBeChargedAmount == 0) {
          return true
        }

        return this.totalPrice && (this.useExistingPaymentMethod != null || this.cardComplete);
      },

      steps() {
        return this.$store.getters['guestBooking/enabledSteps']
      },

      currentStep() {
        return this.$store.state.guestBooking.currentStep
      },

      show() {
        return this.$store.getters['guestBooking/show']
      },

      code() {
        return this.$store.getters['guestBooking/code']
      },

      queryCode() {
        return this.$route.query.code
      },

      guestioCashAmount() {
        return parseFloat(this.guestioCashBalance > this.totalPrice ? this.totalPrice : this.guestioCashBalance)
      },

      toBeChargedAmount() {
        if (this.code.code && this.code.isValid) {
          return parseFloat(0).toFixed(2);
        }

        if (! this.useGuestioCash) {
          return parseFloat(this.totalPrice)
        }

        let amount = (parseFloat(this.totalPrice) - this.guestioCashAmount).toFixed(2)

        return this.guestioCashAmount < this.totalPrice && amount < 0.5 ? (0.5).toFixed(2) : amount
      },

      upsells() {
        return this.$store.getters['guestBooking/upsells']
      },

      upsellsPrice() {
        return this.upsells.reduce((total, upsell) => {
          return upsell.price + total
        }, 0)
      },

      isPitch() {
        return this.$store.getters['guestBooking/isPitch'];
      },

      wantsGuaranteedResponse() {
        return this.$store.getters['guestBooking/wantsGuaranteedResponse'];
      },

      pitchSettings() {
        return this.guest.pitch_settings;
      },

      guaranteedResponsePrice() {
        return this.wantsGuaranteedResponse
          ? this.pitchSettings.guaranteed_response_price
          : 0;
      },

      paymentMethodToUse() {
        if (this.useExistingPaymentMethod) {
          return this.paymentMethods.find(pm => pm.id == this.useExistingPaymentMethod);
        }
        return this.defaultPaymentMethod;
      },
    },

    methods: {
      async createBooking() {
        if (this.toBeChargedAmount > 0) {
          let confirmed = await this.confirmLogin()

          if (! confirmed) {
            return
          }
        }

        this.loading = true
        this.paymentSetupError = false
        this.errorDetails = null

        // TODO: check if user can create bookings or pitches

        if (this.code && this.code.isValid) {
          return this.createBookingWithCode()
        }

        if (this.toBeChargedAmount == 0 || this.useExistingPaymentMethod) {
          return this.storeBooking()
        }

        const { setupIntent, error } = await confirmCardSetup(this.intent.client_secret, {
          payment_method: {
            card: this.card
          }
        })

        if (error) {
          this.loading = false
          this.paymentSetupError = true

          if (error.message) {
            this.errorDetails = error.message
          }

          return
        }

        this.$store.commit('guestBooking/setPaymentMethod', setupIntent.payment_method)

        await this.$store.dispatch('auth/storePaymentMethod', {
          payment_method: setupIntent.payment_method,
        }).catch(error => {
          if (error.response) {
            this.errorDetails = error.response.data.message
          }
        })

        this.storeBooking(setupIntent.payment_method)
      },

      storeBooking(paymentMethod = null) {
        this.$store.dispatch('guestBooking/create').then(booking => {
          if(booking == null) {
            this.openMaxBookingsModal();
          } else if(booking == 'max_pitches_reached') {
            if (this.userIsPro) {
              this.openMaxPitchesModalForPro();
            } else {
              this.openMaxPitchesModal();
            }
          } else {
            this.$router.push({ name: 'BookingSuccess', params: { booking } })
          }
        }).catch(error => {
          if (error.response) {
            this.errorDetails = error.response.data.message
          }
          this.generatePaymentIntent()
          this.loading = false
          this.paymentSetupError = true
        }).finally(() => {
          if (!this.saveCard && paymentMethod) {
            api.delete(`/user/payment/methods/${paymentMethod}`)
          }
        })
      },

      createBookingWithCode() {
        this.$store.dispatch('guestBooking/create').then(booking => {
          // TODO: should we check for max pitches here too?
          if(booking == null) {
            this.openMaxBookingsModal();
          } else {
            this.$router.push({ name: 'BookingSuccess', params: { booking } })
          }
        }).catch(error => {
          if (error.response) {
            this.errorDetails = error.response.data.message
          }
          this.loading = false
        })
      },

      prevStep() {
        let prevStepRoute = this.steps[this.currentStep - 2].route

        this.$router.push({ name: prevStepRoute })
      },

      generatePaymentIntent() {
        let appends = '';

        if (this.useExistingPaymentMethod) {
          appends = `?payment_method=${this.useExistingPaymentMethod}`
        }

        api.get(`/user/payment/intent${appends}`).then(response => {
          this.intent = response.data.intent
        })
      },

      fetchPaymentMethods() {
        api
          .get('/user/payment/methods')
          .then(({ data }) => {
            if (! data.length) {
              this.generatePaymentIntent()
              return
            }
            this.defaultPaymentMethod = data.find(pm => pm.default);
            this.paymentMethods = data
            this.useExistingPaymentMethod = this.defaultPaymentMethod ? this.defaultPaymentMethod.id : data[0].id
          })
      },

      fetchUserBalance() {
        api.get('/balance')
          .then(({ data }) => {
            const rawGuestioCash = Number.isNaN(Number.parseFloat(data.data.raw.guestio_cash))
              ? 0
              : parseFloat(data.data.raw.guestio_cash)

            this.guestioCashBalance = Number.parseFloat(rawGuestioCash / 100).toFixed(2);
          })
          .catch(() => {
            //
          })
      },

      openMaxBookingsModal() {
        this.maxBookingsReached = true;
        this.loading = false;
      },

      openMaxPitchesModal() {
        this.maxPitchesReached = true;
        this.loading = false;
      },

      openMaxPitchesModalForPro() {
        this.maxPitchesReachedForPro = true;
        this.loading = false;
      },

      determineStepNumber() {
        let step = this.steps.findIndex(step => step.name == 'payment');

        if (step === -1) {
          return this.$router.push({ name: this.steps[0].route })
        }

        this.$store.commit('guestBooking/setCurrentStep', step + 1)
      },

      setCardComplete(data) {
        this.cardComplete = data.complete;
        this.card = data.card;
      },

      setSaveCard(shouldSave) {
        this.saveCard = shouldSave;
      },
    },

    mounted() {
      this.determineStepNumber()

      if (! this.show || ! this.show.id) {
        this.$router.push({name: this.steps[0].route})
        return
      }

      if (! this.selectedBookingOption && !this.isPitch) {
        this.$router.push({name: 'GuestBookingOptions'})
        return
      }

      if (! this.selectedBookingOption && this.isPitch) {
        this.$router.push({name: 'GuestPitch'});
        return;
      }

      if (! this.selectedBookingOption.price && !this.isPitch) {
        this.$router.push({name: 'GuestBookingOptions'})
        return
      }

      if (! this.selectedBookingOption.price && this.isPitch) {
        this.$router.push({name: 'GuestPitch'})
        return
      }

      if (this.selectedBookingOption.slug === 'miniview' && ! this.miniviewQuestions.length) {
        this.$router.push({name: 'GuestBookingMiniviewQuestions'})
        return
      }

      this.fetchPaymentMethods()
      this.fetchUserBalance()

      this.price = ['miniview', 'media-feature'].includes(this.selectedBookingOption.slug)
        ? this.selectedBookingOption.price.price * (this.selectedBookingOption.amount || 1)
        : this.selectedBookingOption.price.price

      if (this.isPitch) {
        this.price = this.userIsPro && this.pitchSettings.pro_is_free
          ? 0
          : this.pitchSettings.price;
      }
    },

    destroyed() {
      this.$store.commit('guestBooking/setPaymentMethod', null)
    },
  }
</script>
